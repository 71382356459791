/* eslint "react/jsx-no-constructed-context-values": "off" */
import dynamic from 'next/dynamic';
import React, { createElement, Fragment, useMemo } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { decode } from 'js-base64';
import { Node, Element } from 'domhandler';
import { HeadingBorder } from '@stereoagency/ui';
import { LinesContext } from '../LinesContext';

type Props = {
  html: string
  enableLines?: boolean
};

const components = {
  'nviso-text-intermission': dynamic(() => import('./interop/NvisoTextIntermission')),
  'nviso-simple-content': dynamic(() => import('./interop/NvisoSimpleContent')),
  'nviso-icon-info-list': dynamic(() => import('./interop/NvisoIconInfoList')),
  'nviso-certifications': dynamic(() => import('./interop/NvisoCertifications')),
  'nviso-cta-banner': dynamic(() => import('./interop/NvisoCtaBanner')),
  'nviso-page-intro': dynamic(() => import('./interop/NvisoPageIntro')),
  'nviso-icon-info-grid': dynamic(() => import('./interop/NvisoIconInfoGrid')),
  'nviso-news-grid': dynamic(() => import('./interop/NvisoNewsGrid')),
  'nviso-events-grid': dynamic(() => import('./interop/NvisoEventsGrid')),
  'nviso-jobs-list': dynamic(() => import('./interop/NvisoJobsList')),
  'nviso-company-logos': dynamic(() => import('./interop/NvisoCompanyLogos')),
  'nviso-testimonials': dynamic(() => import('./interop/NvisoTestimonials')),
  'nviso-image-and-content': dynamic(() => import('./interop/NvisoImageAndContent')),
  'nviso-key-figures': dynamic(() => import('./interop/NvisoKeyFigures')),
  'nviso-contact-form': dynamic(() => import('./interop/NvisoContactForm')),
  'nviso-code-block': dynamic(() => import('./interop/NvisoCodeBlock')),
  'nviso-divider': dynamic(() => import('./interop/NvisoDivider')),
  'nviso-vertical-tabs-section': dynamic(() => import('./interop/NvisoVerticalTabsSection')),
  'nviso-container-content': dynamic(() => import('./interop/NvisoContainerContent')),
  'nviso-service-items-grid': dynamic(() => import('./interop/NvisoServiceItemsGrid')),
  'nviso-horizontal-masonry-gallery': dynamic(() => import('./interop/NvisoHorizontalMasonryGallery')),
  'nviso-large-hero': dynamic(() => import('./interop/NvisoLargeHero')),
  'nviso-gradient-title-section': dynamic(() => import('./interop/NvisoGradientTitleSection')),
  'nviso-numbered-info-cards-grid': dynamic(() => import('./interop/NvisoNumberedInfoCardsGrid')),
  'nviso-icon-details-grid': dynamic(() => import('./interop/NvisoIconDetailsGrid')),
  'nviso-icon-details-grid-carousel': dynamic(() => import('./interop/NvisoIconDetailsGridCarousel')),
  'nviso-team-cards-section': dynamic(() => import('./interop/NvisoTeamCardsSection')),
  'nviso-info-cards-grid': dynamic(() => import('./interop/NvisoInfoCardsGrid')),
  'nviso-popover-banner': dynamic(() => import('./interop/NvisoPopoverBanner')),
};

export const htmlToReactReplaceFn = (enableLines = false) => (domNode: Node) => {
  if ('tagName' in domNode && 'attribs' in domNode) {
    const {
      tagName, attribs, childNodes,
    } = domNode as Element;

    if (tagName === 'h3') {
      return (
        <HeadingBorder>
          {createElement('h3', {}, domToReact(childNodes))}
        </HeadingBorder>
      );
    }

    if (tagName === 'h4') {
      return (
        <HeadingBorder>
          {createElement('h4', {}, domToReact(childNodes))}
        </HeadingBorder>
      );
    }

    if (tagName === 'div' && attribs.class?.includes('wp-block-lazyblock-')) {
      return createElement(Fragment, {}, domToReact(childNodes, {
        replace: htmlToReactReplaceFn(enableLines),
      }));
    }

    if (
      tagName === 'x-component' && !!attribs['data-name']
    ) {
      const blockName = attribs['data-name'];
      const blockProps = attribs['data-props'];

      try {
        const props = JSON.parse(decode(blockProps));

        if (blockName in components) {
          const Component = components[
            blockName as keyof typeof components
          ] as React.ComponentType<any>;

          return (
            <LinesContext.Provider value={{ enableLines: enableLines ?? false }}>
              <Component node={domNode} data={props} __debug={{ blockName, blockProps }} />
            </LinesContext.Provider>
          );
        }
      } catch (err) {
        console.error(err);
        console.log(`Failed to render block ${blockName}`, attribs);
      }

      return null;
    }

    return null;
  }

  return domNode;
};

export function ContentRenderer({ html, enableLines }: Props) {
  const children = useMemo(() => {
    if (typeof html !== 'string') {
      return html;
    }
    return parse(html, {
      replace: htmlToReactReplaceFn(enableLines),
    });
  }, [html]);
  return createElement(Fragment, {}, children);
}
